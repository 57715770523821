import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async sendNotifications(vuexContext, { message, tokens }) {
        const { data } = await axios.post(`/notifications/push-notifications`, {
            message,
            tokens
        });

        return data;
    }
};
